import $axios from './index'

// 我的首页
export function mine_index(data) {
	const url = '/api/app/v1/mine/index';
	return $axios.post(url, data);
}
//课程所有一级分类
export function first_types(data) {
	const url = '/api/app/v1/course/type/first/types';
	return $axios.post(url, data);
}
//我的优惠券
export function coupon_list2(data) {
	const url = '/api/app/v1/mine/coupon/list';
	return $axios.post(url, data);
}
//我的优惠券
export function coupon_list(data) {
	const url = '/api/pc/v1/mine/coupon/list';
	return $axios.post(url, data);
}
//我的积分
export function point_list(data) {
	const url = '/api/pc/v1/mine/point/list';
	return $axios.post(url, data);
}
//用户协议
export function information_content(data) {
	const url = '/api/pc/v1/index/get/information/content';
	return $axios.get(url, data);
}
//课程购买协议
export function information_content2(data) {
	const url = '/api/app/v1/h5/course/purchase/agreement';
	return $axios.get(url, data);
}
//学习中心
export function study_index(data) {
	const url = 'api/app/v1/course/study/index';
	return $axios.post(url, data);
}
//已购课程列表
export function has_buy(data) {
	const url = '/api/app/v1/course/study/has/buy';
	return $axios.post(url, data);
}
// 最近学习
export function packageList(data) {
	const url = '/api/pc/v1/mine/recentlyLearnedCoursePackageList';
	return $axios.post(url, data);
}
//我的课程答疑
export function dayi_list(data) {
	const url = '/api/pc/v1/mine/coursepackage/mine/course/dayi/list';
	return $axios.post(url, data);
}
//我的课程
export function mine_coures(data) {
	const url = '/api/app/v1/course/study/mine/coures';
	return $axios.post(url, data);
}
//课程包列表
export function course_list(data) {
	const url = '/api/app/v1/course/study/coursepackage/course/list';
	return $axios.post(url, data);
}
//我的直播/（全部直播/直播回放）
export function live_list(data) {
	const url = '/api/pc/v1/mine/mine/live/list';
	return $axios.post(url, data);
}
//我的笔记
export function mine_notes(data) {
	const url = '/api/pc/v1/mine/coursepackage/mine/notes';
	return $axios.post(url, data);
}
//笔记修改
export function notes_edit(data) {
	const url = '/api/pc/v1/mine/coursepackage/mine/notes/edit';
	return $axios.post(url, data);
}
//笔记删除
export function notes_del(data) {
	const url = '/api/pc/v1/mine/coursepackage/mine/notes/del';
	return $axios.post(url, data);
}
//我的问答
export function wd_list(data) {
	const url = '/api/pc/v1/mine/wd/list';
	return $axios.post(url, data);
}
//问答列表
export function detail_list(data) {
	const url = '/api/app/v1/wd/detail/list';
	return $axios.post(url, data);
}
//我的消息/消息列表
export function notification_list(data) {
	const url = '/api/app/v1/mine/notification/list';
	return $axios.post(url, data);
}
//图片上传oss
export function getSign(data) {
	const url = '/api/pc/alioss/getSign';
	return $axios.get(url, data);
}
//修改信息
export function edit_info(data) {
	const url = '/api/app/v1/mine/edit/info';
	return $axios.post(url, data);
}
//修改密码
export function update_password(data) {
	const url = '/api/frame/v1/customer/update/password';
	return $axios.post(url, data);
}
//绑定手机号
export function binding_mobile(data) {
	const url = '/api/frame/v1/customer/binding/mobile';
	return $axios.post(url, data);
}
//校验验证码
export function verification_code(data) {
	const url = '/api/frame/v1/customer/verification/code';
	return $axios.post(url, data);
}
//已兑换套餐列表
export function package_list(data) {
	const url = '/api/pc/v1/mine/exchanged/course/package/list';
	return $axios.post(url, data);
}
//我要兑换/获取兑换信息
export function get_exchange_info(data) {
	const url = '/api/app/v1/mine/exchange/get/info';
	return $axios.post(url, data);
}
//我要兑换/进行兑换
export function do_exchange_info(data) {
	const url = '/api/app/v1/mine/exchange/do/exchange';
	return $axios.post(url, data);
}
//我的订单/订单列表
export function order_list(data) {
	const url = '/api/app/v1/mine/order/list';
	return $axios.post(url, data);
}
//我的订单/取消订单
export function order_cancel(data) {
	const url = '/api/app/v1/mine/order/cancel';
	return $axios.post(url, data);
}
//我的订单/订单详情
export function order_detail(data) {
	const url = '/api/app/v1/mine/order/detail';
	return $axios.post(url, data);
}
//我的订单/查看物流
export function get_logistics(data) {
	const url = '/api/app/v1/mine/order/get/logistics/info';
	return $axios.post(url, data);
}
//我的订单/继续支付
export function order_pay(data) {
	const url = '/api/pc/v1/pay/course/package/continue/pay';
	return $axios.post(url, data);
}
//课程购买协议
export function zhiboliebiao(data) {
	const url = '/api/app/v1/course/package/live/list';
	return $axios.post(url, data);
}
//我的-轮播图
export function banners(data) {
	const url = '/api/pc/v1/mine/advertise/list';
	return $axios.post(url, data);
}
//我的-轮播图
export function chakanhuifang(data) {
	const url = '/api/app/v1/live/playback';
	return $axios.post(url, data);
}
export function wodedayi(data) {
	const url = '/api/pc/v1/mine/coursepackage/mine/dy';
	return $axios.post(url, data);
}


//今日直播
export function liveTodayList(data) {
	const url = '/api/app/v1/live/liveTodayList';
	return $axios.post(url, data);
}
//直播回放
export function livePlaybackList(data) {
	const url = '/api/app/v1/live/livePlaybackList';
	return $axios.post(url, data);
}

//直播回放
export function coursepackageList(data) {
	const url = '/api/app/v1/course/package/live/coursepackage/list';
	return $axios.post(url, data);
}


// 学习中心- 我的直播 -- 直播课程:课程分类
export function courseTypeList(data){
	const url = '/api/app/v1/course/package/live/course/type/list';
	return $axios.post(url, data);
}

// 学习中心- 我的直播 -- 直播课程:课程分类列表
export function liveCourseList(data){
	const url = '/api/app/v1/course/package/live/course/list';
	return $axios.post(url, data);
}

// 追问添加模块 -- 添加接口
export function aqAdd(data){
	const url = '/api/pc/v1/course/study/coursepackage/course/aq/add';
	return $axios.post(url, data);
}


export function getLoginStatus(){
	const url = '/api/chanjet/v1/uoc/getTenantLoginStatusAndSinglePointAddressV2';
	return $axios.get(url);
}