<template>
    <div class="myOrder">
        <div class="myOrder_header">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部订单" name="0">
                    <div class="myOrder_list">
                        <div class="myOrder_list_item" v-for="(item,index) in orderList" :key="index" >
                            <div class="myOrder_list_item_header">
                                <div class="myOrder_list_item_header_left">
                                    <p>
                                        <span>订单号：</span>
                                        <span>{{item.orderId}}</span>
                                    </p>
                                    <p>下单时间：{{item.orderTime}}</p>
                                </div>
                                <div class="myOrder_list_item_header_right" v-if="item.orderType==0">课程</div>
                                <div class="myOrder_list_item_header_right" v-if="item.orderType==1">直播课</div>
                                <div class="myOrder_list_item_header_right" v-if="item.orderType==2">问答会员</div>
                            </div>
                            <div class="myOrder_list_item_box">
                                <div class="myOrder_list_item_box_one">
                                    <div class="myOrder_list_item_box_one_top">
                                        <img :src="item.coverPath" alt="" v-if="item.coverPath">
                                        <span>{{item.orderName}}</span>
                                    </div>
                                </div>
                                <div class="myOrder_list_item_box_two">
                                    <p class="myOrder_list_item_box_two_view">
                                        <span>¥{{item.actuallyPrice}}</span>
                                    </p>
                                </div>
                                <div class="myOrder_list_item_box_three" >
                                    <p v-if="item.payState==0">未付款</p>
                                    <p v-if="item.payState==1">已支付</p>
                                    <p v-if="item.payState==2">已取消</p>
                                    
                                </div>
                                <div class="myOrder_list_item_box_four" >
                                    <p  v-if="item.time!=null && item.payState ==0">
                                        <img src="@/assets/mySelf/countDown.png" alt="" v-if="item.time!=null">
                                        <span v-if="item.time!=null">{{item.time}}</span>
                                    </p>
                                    <p v-if="item.payState==0">
                                        <button @click="nowPay(item)">立即支付</button>
                                    </p>
                                    <p>
                                        <span v-if="item.payState==0" @click="cancelOrder(item,index)" style="cursor:pointer;">取消订单</span>
                                        <span v-if="item.orderType ==0 || item.orderType==1" v-on:click.stop="toDetail('/myself/myOrderDetail',item)" style="cursor:pointer;margin-left:10px;color:#5f7cfd;">订单详情</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="coursediv_empty" v-if="!orderList.length">
                            <div class="coursediv_empty_img">
                                <img src="@/assets/class_empty.png" alt="">
                            </div>
                            <div class="coursediv_empty_text">暂无数据</div>
                        </div>

                    </div>
                </el-tab-pane>
                <el-tab-pane label="待付款" name="1">
                    <div class="myOrder_list">
                        <div class="myOrder_list_item" v-for="(item,index) in orderList" :key="index" >
                            <div class="myOrder_list_item_header">
                                <div class="myOrder_list_item_header_left">
                                    <p>
                                        <span>订单号：</span>
                                        <span>{{item.orderId}}</span>
                                    </p>
                                    <p>{{item.orderTime}}</p>
                                </div>
                                <div class="myOrder_list_item_header_right" v-if="item.orderType==0">课程</div>
                                <div class="myOrder_list_item_header_right" v-if="item.orderType==1">直播课</div>
                                <div class="myOrder_list_item_header_right" v-if="item.orderType==2">问答会员</div>
                            </div>
                            <div class="myOrder_list_item_box">
                                <div class="myOrder_list_item_box_one">
                                    <div class="myOrder_list_item_box_one_top">
                                        <img :src="item.coverPath" alt="">
                                        <span>{{item.orderName}}</span>
                                        <!-- <span>X 1</span> -->
                                    </div>
                                    <!-- <div class="myOrder_list_item_box_one_bottom" v-if="item.giveCourseNames != '' && item.giveCourseNames != null && item.giveCourseNames != undefined">
                                        <div class="myOrder_list_item_box_one_bottom_item" v-for="(listItem,index) in item.giveCourseNames" :key="index">
                                            <span>{{listItem}}</span>
                                        </div>
                                        <span>
                                            <img src="" alt="">
                                        </span>
                                    </div> -->
                                </div>
                                <div class="myOrder_list_item_box_two">
                                    <!-- <p>
                                        <span>总价：</span>
                                        <span>¥6999</span>
                                    </p>
                                    <p>
                                        <span>优惠券：</span>
                                        <span>¥20</span>
                                    </p>
                                    <p>
                                        <span>积分抵扣：</span>
                                        <span>¥78</span>
                                    </p> -->
                                    <p class="myOrder_list_item_box_two_view">
                                        <span>¥{{item.actuallyPrice}}</span>
                                    </p>
                                </div>
                                <div class="myOrder_list_item_box_three">
                                    <p v-if="item.payState==0">未付款</p>
                                    <p v-if="item.payState==1">已支付</p>
                                    <p v-if="item.payState==2">已取消</p>
                                    
                                </div>
                                <div class="myOrder_list_item_box_four">
                                    <p >
                                        <img src="@/assets/mySelf/countDown.png" alt="" v-if="item.time!=null">
                                        <span v-if="item.time!=null ">{{item.time}}</span>
                                    </p>
                                    <p v-if="item.payState==0">
                                        <button @click="nowPay(item)">立即支付</button>
                                    </p>
                                    <!-- <p v-if="item.payState==0" @click="cancelOrder(item,index)">取消订单</p>
                                    <p v-on:click.stop="toDetail('/myself/myOrderDetail',item)" >订单详情</p> -->
                                    <p>
                                        <span v-if="item.payState==0" @click="cancelOrder(item,index)" style="cursor:pointer;">取消订单</span>
                                        <span v-on:click.stop="toDetail('/myself/myOrderDetail',item)" style="cursor:pointer;margin-left:10px;">订单详情</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="coursediv_empty" v-if="!orderList.length">
                            <div class="coursediv_empty_img">
                                <img src="@/assets/class_empty.png" alt="">
                            </div>
                            <div class="coursediv_empty_text">暂无数据</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="已付款" name="2">
                    <div class="myOrder_list">
                        <div class="myOrder_list_item" v-for="(item,index) in orderList" :key="index" >
                            <div class="myOrder_list_item_header">
                                <div class="myOrder_list_item_header_left">
                                    <p>
                                        <span>订单号：</span>
                                        <span>{{item.orderId}}</span>
                                    </p>
                                    <p>{{item.orderTime}}</p>
                                </div>
                                <div class="myOrder_list_item_header_right" v-if="item.orderType==0">课程</div>
                                <div class="myOrder_list_item_header_right" v-if="item.orderType==1">直播课</div>
                                <div class="myOrder_list_item_header_right" v-if="item.orderType==2">问答会员</div>
                            </div>
                            <div class="myOrder_list_item_box">
                                <div class="myOrder_list_item_box_one">
                                    <div class="myOrder_list_item_box_one_top">
                                        <img :src="item.coverPath" alt="" v-if="item.coverPath">
                                        <span>{{item.orderName}}</span>
                                        <!-- <span>X 1</span> -->
                                    </div>
                                    
                                </div>
                                <div class="myOrder_list_item_box_two">
                                    <!-- <p>
                                        <span>总价：</span>
                                        <span>¥6999</span>
                                    </p>
                                    <p>
                                        <span>优惠券：</span>
                                        <span>¥20</span>
                                    </p>
                                    <p>
                                        <span>积分抵扣：</span>
                                        <span>¥78</span>
                                    </p> -->
                                    <p class="myOrder_list_item_box_two_view">
                                        <span>¥{{item.actuallyPrice}}</span>
                                    </p>
                                </div>
                                <div class="myOrder_list_item_box_three">
                                    <p v-if="item.payState==0">未付款</p>
                                    <p v-if="item.payState==1">已支付</p>
                                    <p v-if="item.payState==2">已取消</p>
                                </div>
                                <div class="myOrder_list_item_box_four">
                                    <span v-if="item.orderType ==0 || item.orderType==1" v-on:click.stop="toDetail('/myself/myOrderDetail',item)" style="cursor:pointer;margin-left:10px;color:#5f7cfd;">订单详情</span>
                                </div>
                            </div>
                        </div>
                        <div class="coursediv_empty" v-if="!orderList.length">
                            <div class="coursediv_empty_img">
                                <img src="@/assets/class_empty.png" alt="">
                            </div>
                            <div class="coursediv_empty_text">暂无数据</div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <!-- <div class="myOrder_header_search">
                <div class="myOrder_header_search_box">
                    <p>
                        <img src="@/assets/mySelf/search_icon.png" alt="">
                        <input type="text" value="" placeholder="输入订单号或课程关键字">
                    </p>
                    <p>搜索</p>
                </div>
            </div> -->
            <!-- 付钱弹窗 -->
            <div class="dialog">
                <el-dialog 
                    title="微信扫码支付"
                    :visible.sync="centerDialogVisible"
                    width="570px"
                    center>
                    <!-- <p class="dialog_between"><span>课程名称：</span><span>{{courseMap.name}}</span></p>
                    <p class="dialog_between"><span>应付金额：</span><span class="color_red">¥{{courseMap.totalPrices}}</span></p> -->
                    <p class="line"></p>
                    <p class="dialog_between" style="text-align:center;"><span>微信扫码</span></p>
                    <div id="qrcode" ref='qrcode'  class="dialog_center dialog_img" style="width: 100%;display: flex;justify-content: center;"></div>
                    <p class="dialog_center" style="text-align:center;">请使用微信扫码，支付成功后自动开通服务</p>
                    <!-- <span slot="footer" class="dialog-footer">
                        <el-button @click="tonext"> 我已完成支付 </el-button>
                    </span> -->
                </el-dialog>
            </div>
        </div>
    </div>
    
    
</template>

<script>
import { order_list,order_cancel,order_pay } from "@/api/myself";
export default {
    name:'myOrder',
    watch:{
        $route(to,from){
            console.log(from.path);//从哪来
            console.log(to.path);//到哪去
        },
        centerDialogVisible(oldvalue,newvalue){
            console.log(oldvalue)
            console.log(newvalue)
            if(oldvalue==false){
                this.$refs.qrcode.innerHTML = "";//清空渲染的二维码
            }
        },
        
    },
    data(){
        return{
            activeName:'0',
            page:0,
            type:0,
            orderList:[],
            centerDialogVisible:false,// 二维码支付
        }
    },

    mounted(){
        this.order_list()
    },
    methods:{
        nowPay(item){
            console.log(item);
            var obj={
                orderId:item.orderId
            }
            order_pay(obj).then(res=>{
                console.log(res)
                if(res.data.code ==0){
                    if(res.data.data.payWay=='支付宝'){
                        const div = document.createElement('div')//创建div
                        div.innerHTML = res.data.data.aliSign
                        document.body.appendChild(div)
                        console.log(document.body)
                        document.forms[0].submit();
                    }else if(res.data.data.payWay=='微信'){
                        this.centerDialogVisible=true
                        console.log(res.data.data.codeUrl)
                        // that.serviceId=res.data.data.serviceId
                        setTimeout(()=>{
                            this.$QRCodeSC(res.data.data.codeUrl)
                        },1000)
                        // this.order_status()
                        this.order_list();
                    }else if(res.data.data.payWay =='网银'){
                        window.location.href=res.data.data.url
                    }
                }
                
            }).catch(error=>{
                console.log(error)
            })
        },
        cancelOrder(item,index){
            this.$confirm('确认要取消订单吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    var obj={
                        orderId:item.orderId
                    }
                    order_cancel(obj).then(res=>{
                        console.log(res)
                        if(res.data.code==0){
                            this.orderList[index].payState=2
                        }
                    }).catch(error=>{
                        console.log(error)
                    })
                }).catch(() => {
            });
        },
        // 跳转页面
		toDetail(page,item) {
            console.log(item)
			this.$router.push({
				path: page,
				query: {
                    orderId:item.orderId,
                    orderType:item.orderType
                },
			});
		},
        handleClick(tab,event){
            console.log(tab)
            console.log(event)
            if(tab.index==0){
                this.type=0
                this.page=0
                this.order_list()
            }else if(tab.index==1){
                this.type=1
                this.page=0
                this.order_list()
            }else if(tab.index==2){
                this.type=2
                this.page=0
                this.order_list()
            }
        },
        // 获取订单列表
        order_list(){
            var obj={
                page:this.page,
                type:this.type
            }
            order_list(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.orderList=res.data.data
                    for(var i in this.orderList){
                        this.orderList[i].timestamp = Date.parse(this.orderList[i].orderTime.replace(/-/g, '/'))/1000;
                        this.orderList[i].time = '';
                    }
                    if(this.orderList.length>0){
                        this.countDownTime()
                    }
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        //倒计时
        countDownTime(){
            console.log(Date.parse(this.orderList[0].orderTime.replace(/-/g, '/'))/1000)
            setInterval(this.formatDate,1000)
        },  
        formatDate(){
            
            var orderList = this.orderList
            for(var i in orderList){
                //结束时间
                var endTime = Date.parse(orderList[i].orderTime.replace(/-/g, '/'))/1000 + 900;
                // console.log('endTime',endTime)
                //当前时间
                var startTime = Date.parse(new Date())/1000;
                // console.log('startTime',startTime)
                //剩余时间
                var timeFormat = endTime - startTime;
                // console.log('timeFormat',timeFormat)
                // var timeFormat = 0
                if(timeFormat > 0){
                    //转换时间格式
                    var time= this.countDownmintus(timeFormat);
                    var obj=orderList[i]
                    obj.time=time
                    // console.log(time)
                    // orderList[i].time = time;
                    this.$set(this.orderList, i, obj);
                }else{
                    var time= null;
                    var obj=orderList[i]
                    obj.time=time
                    // console.log(time)
                    // orderList[i].time = time;
                    this.$set(this.orderList,i, obj);
                    if(obj.payState ==0){
                        var obj={
                            orderId:orderList[i].orderId
                        }
                        order_cancel(obj).then(res=>{
                            console.log(res)
                            if(res.data.code==0){
                                //刷新
                                this.order_list()
                            }
                        }).catch(error=>{
                            console.log(error)
                        })
                    }
                    
                    clearInterval(this.formatDate);
                }
                
                //重新赋值
                // orderList[i].time = 0
            }
            // this.orderList=orderList
            
        },
        //转换时间格式
        countDownmintus(data){
            var s;
            var hours = parseInt((data % (60 * 60 * 24)) / (60 * 60));
            // console.log(hours)
            var minutes = parseInt((data % (60 * 60)) / 60);
            // console.log(minutes)
            var seconds = parseInt((data % 60));
            // console.log(seconds)
            s = (hours < 10 ? ('0' + hours) : hours) + ':' + (minutes < 10 ? ('0' + minutes) : minutes) + ':' + (seconds < 10 ? ('0' + seconds) : seconds);
            return s;
        },
    }
}
</script>

<style>

</style>